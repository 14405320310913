import React from "react";
import "./member.scss";

const Member = ({ member }) => {
  return (
    <div className="member-details">
      {member.photo && <img src={member.photo} />}
      <div className="member-description">
        <div>{member.full_name}</div>
        <div>{member.role}</div>
      </div>
    </div>
  );
};

export default Member;
