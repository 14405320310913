import React from "react";
import blogMobile from "../../../../assets/images/news/blog-mobile.png";
import "./Intro.scss";

const Intro = () => {
  return (
    <div className="blog">
      <div className="students">
        <img className="blog-img" src={blogMobile} alt="writing hand" />
      </div>
      <div className="blog-text">
        <div className="blog-title">WHAT'S NEW ?</div>
        <div className="text">
          Rath Tutoring’s Latest Insights on Testing & Higher Education
        </div>
      </div>
    </div>
  );
};

export default Intro;
