import React from "react";
import close from "../../../../assets/images/home/close_icon.svg";

import "./PrivacyPopup.scss";

const PrivacyPopup = ({ acceptPolicy, closePolicy }) => {
  return (
    <div className="policy-overlay">
      <div className="flex">
        <div className="policy-text">
          By continuing browsing on this website, you agree to our{" "}
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="/terms-and-conditions" target="_blank">
            Terms of Use
          </a>
          .
        </div>
        <img
          src={close}
          className="close-policy"
          onClick={() => closePolicy()}
        />
      </div>
      <div className="ok-button">
        <div onClick={() => acceptPolicy()}>OK</div>
      </div>
    </div>
  );
};

export default PrivacyPopup;
