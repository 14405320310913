import React from "react";
import PostCard from "../post_card/PostCard";
import PostActions from "../post_actions/PostActions";
import "./List.scss";

const List = ({ posts, admin }) => {
  return (
    <div className="news-list">
      {posts.map((post) => {
        return (
          <div key={post.id} className="post-list-item">
            <a
              href={`/posts/${post.id}#post-${post.id}`}
              className="news-post-link"
              key={post.id}
              id={`post-${post.id}`}
            >
              <PostCard post={post} admin={admin} />
            </a>
            <PostActions admin={admin} post={post} />
          </div>
        );
      })}
    </div>
  );
};

export default List;
