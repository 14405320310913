import React from 'react';
import logo from '../../../../assets/images/logo/logo-home.svg';
import './Intro.scss';

const Intro = () => {
  return (
    <div className="home-intro">
      <div className="hand">
        <div className="overlay flex-column">
          <div className="image-container">
            <img className="logo" src={logo} alt="Rath Tutoring logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
