import React from "react";
import "./AdminTasks.scss";

const AdminTasks = ({user}) => {
  return (
    <div className="page">
      <div className="admin-action-buttons">
        <a href="/posts">Manage blog</a>
        {!!user.admin && 
          <>
            <a href="/administrators">Manage administrators</a>
            <a href="/users">Manage users</a>
          </>
        }
      </div>
    </div>
  );
};

export default AdminTasks;
