import React from "react";
import DesktopHeader from "./desktopHeader/DesktopHeader.js";
import MobileHeader from "./mobileHeader/MobileHeader.js";
import "./Header.scss";


const Header = ({ path, admin }) => {

  return (
    <header>
      <DesktopHeader path={path} admin={admin} />
      <MobileHeader />
    </header>
  );
};

export default Header;
