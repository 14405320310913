import React       from 'react';
import Intro       from './intro/Intro.js';
import Approach    from './approach/Approach.js';
import Infographic from './infographic/Infographic.js';

const TutoringPrograms = () => {
  return (
    <div className="page">
      <Intro />
      <Approach />
      <Infographic />
    </div>
  );
};

export default TutoringPrograms;
