import React from 'react';
import './CoreValues.scss';

const CoreValues = () => {
  return (
    <div className='values-background'>
      <div className="our-values block">
        <div className="section-title title">Core Values</div>
        <div className="content">
          <div>
            <div className="subtitle">TRUSTWORTHY</div>
            <div className="text-block">
             We honestly and accurately represent ourselves and services with 
             all interactions, internal and external.  
            </div>
          </div>

          <div>
            <div className="subtitle">OPENNESS</div>
            <div className="text-block">
              We are a culture open to change, new ideas, collaboration, and the exchange of information to grow our business and our own development.
            </div>
          </div>

          <div>
            <div className="subtitle">EFFICIENCY</div>
            <div className="text-block">
              We use resources wisely, leverage modern technology, and continually focus on developing streamlined processes.
            </div>
          </div>

          <div>
            <div className="subtitle">DEDICATED</div>
            <div className="text-block">
              We bring a strong work ethic and reliability to all aspects of our work.
            </div>
          </div>

          <div>
            <div className="subtitle">CLIENT CENTERED</div>
            <div className="text-block">
              The success of our clients is the foundation of our own success, and we are committed to identifying and meeting their needs.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
