import React, { useState } from 'react';
import './SubscriptionForm.scss';

const SubscriptionForm = ({
  status,
  message,
  onValidated,
  newsLetterSubscribed,
}) => {
  let email, firstName, lastName;

  const submit = () => {
    email &&
      firstName &&
      lastName &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
        MERGE1: firstName.value,
        MERGE2: lastName.value,
      });
  };

  if (status === 'success') {
    setTimeout(() => {
      newsLetterSubscribed();
    }, 3000);
  }

  return (
    <div>
      {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
      {status === 'error' && (
        <div
          style={{ color: 'red' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          style={{ color: 'green' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="flex subscription-flex">
        <input
          ref={(node) => (firstName = node)}
          type="text"
          placeholder="First name"
        />

        <input
          ref={(node) => (lastName = node)}
          type="text"
          placeholder="Last name"
        />

        <input
          ref={(node) => (email = node)}
          type="email"
          placeholder="Email address"
        />

        <input
          type="submit"
          onClick={submit}
          className="submit-button"
          value="Subscribe"
        />
      </div>
    </div>
  );
};

export default SubscriptionForm;
