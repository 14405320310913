import React, { useEffect, useRef } from 'react';
import './PostCard.scss';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';

const PostCard = ({ post, showFull = false, next, admin }) => {
  useEffect(() => {
    const postLocation = document.getElementById(`${window.location.hash}`);
    if (postLocation) {
      window.scrollTo({
        top: postLocation.getBoundingClientRect().y - 120,
      });
    }
  });
  return (
    <div
      className={`news-post-card ${showFull && 'full-post'}`}
      id={`#post-${post.id}`}
    >
      <div className="post-date">{post.date}</div>
      <div className="post-title">{post.title}</div>
      {showFull ? (
        <div
          dangerouslySetInnerHTML={{ __html: post.description }}
          className="post-description"
        />
      ) : (
        <div className="post-description">{post.teaser}</div>
      )}

      <div className="post-meta-section">
        <div className="flex-align-center post-author-section">
          <div className="author-image">
            <img src={post.avatar} alt={post.author} />
          </div>
          <div>
            <div className="author">{post.author}</div>
            <div className="role">{post.role}</div>
          </div>
        </div>
        {showFull && (
          <div className="social-media-share-buttons">
            <h4>Share</h4>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon />
            </LinkedinShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon />
            </TwitterShareButton>
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon />
            </FacebookShareButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostCard;
