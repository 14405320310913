import React       from "react";
import approach    from "../../../../assets/images/tutoring/rath-tutoring-approach.svg";
import "./Infographic.scss";

const Infographic = () => {
  return (
    <div className="infographic block">
      <div className="section-title">Our process</div>
      <div className="infographic-container">
        <img src={approach} alt="Rath tutoring approach" />
      </div>
    </div>
  );
};

export default Infographic;
