import React from "react";
import eric from "../../../../assets/images/about/eric-rath.png";
import "./Founder.scss";

const Team = () => {
  return (
    <div className="about-team">
      <div className="right block">
        <div className="executive section-title">Founder</div>
        <div className="member">
          <img src={eric} alt="rath-eg logo" className="image-circle" />
          <div className="member-info">
            <div className="member-name">Eric Rath</div>
            <div className="member-function">Founder</div>
          </div>
        </div>
        <div className="text-block member-text">
          <br /><br />
          <div>
            Rath Tutoring, founded by Eric Rath in 2016, continues its mission to provide efficient, 
            innovative, and transparent educational support, guided by the belief that all students 
            deserve a personalized approach to learning to achieve their educational goals.
          </div>
          <div>
            Before establishing Rath Tutoring, Eric dedicated more than a decade to one-on-one 
            tutoring, gaining invaluable insights into the benefits of tailored education. His commitment 
            to integrating technology and data-driven strategies has established Rath Tutoring as a 
            leading choice for students seeking customized academic assistance.
          </div>
          <div>
            In addition to his role leading the team at Rath Tutoring, Eric is a respected speaker 
            and consultant in the education sector, recognized for his expertise in higher education, 
            academic assessments, and educational data. He has shared his insights at prestigious 
            institutions and industry events, including Beverly Hills High School, Malibu High School, 
            Sage Hill High School, Menlo School, The Branson School, Stevenson Ranch School, Porto 
            Alegre Intl., Hong Kong Academy, IECA, HECA, WACAC, Intl-ACAC, and NACAC.
          </div>
          <div>
            As a privately owned and operated company, Rath Tutoring is committed solely to the needs 
            of our clients and partners.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
