import React, { useState } from 'react';
import Services            from './services/Services.js';
import PrivacyPopup        from './privacy_popup/PrivacyPopup.js';
import Intro               from './intro/Intro.js';

const Home = () => {
  const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(
    localStorage.getItem('accept-policy')
  );

  const acceptPolicy = () => {
    localStorage.setItem('accept-policy', 'true');
    closePolicy();
  };

  const closePolicy = () => {
    setHasAcceptedPolicy(true);
  };

  return (
    <div className="page">
      <Intro />
      <Services />
      {!hasAcceptedPolicy && (
        <PrivacyPopup acceptPolicy={acceptPolicy} closePolicy={closePolicy} />
      )}
    </div>
  );
};

export default Home;
