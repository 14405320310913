import React from 'react';
import './Approach.scss';

const Approach = () => {
  return (
    <div className='approach-background'>
      <div className="our-approach block">
        <div className="section-title title">Our approach</div>
        <div className="content">
          <div>
            <div className="subtitle">COMMITTED</div>
            <div>
            We create a personalized plan based on each student's goals, timeline, 
            and availability, and consistently monitor progress toward content mastery.
            </div>
          </div>
          <div>
            <div className="subtitle">RIGOROUS</div>
            <div>
              We set high expectations and reinforce to our students the critical
              nature of practice and accountability in improving performance and
              maintaining gains.
            </div>
          </div>
          <div>
            <div className="subtitle">THOROUGH</div>
            <div>
              We have developed our strategies and techniques over decades of
              tutoring, writing curricula, and meticulously analyzing official
              practice material.
            </div>
          </div>
          <div>
            <div className="subtitle">ADAPTIVE</div>
            <div>
              We recognize that learning styles vary, and each tutoring program is
              customized to fit the individual needs of each student.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
