import React from "react";
import "./Vision.scss";

const Vision = () => {
  return (
    <div className="vision">
      <div className="vision-section block">
        <div>
          <div className="section-title">VISION</div>
          <div className="details">
            Education is power. We strive to empower students worldwide to achieve their academic and exam goals.
          </div>
        </div>

        <div>
          <div className="section-title">MISSION</div>
          <div className="details">
            Through personalized and engaging online tutoring and mentorship programs, we instill practice as a habit to build confidence and increase academic and exam aptitude in our students.
          </div>
        </div>
      </div>

      <div className="right"></div>
    </div>
  );
};

export default Vision;
