import React from 'react';
import heca  from '../../../assets/images/educators/heca.png';
import ieca  from '../../../assets/images/educators/ieca.png';
import nacac from '../../../assets/images/educators/nacac.png';
import tabs  from '../../../assets/images/educators/tabs.png';
import cis   from '../../../assets/images/educators/cis.png';
import nassp from '../../../assets/images/educators/nassp.png';
import pto   from '../../../assets/images/educators/pto.png';
import pta   from '../../../assets/images/educators/pta.png';

import './Educators.scss';

const Educators = () => {
  return (
    <div className="page educators">
      <div className="block">
        <div className='educators-heading'>
          <div className='section-title'>
            For Educators
          </div>
          <div className="button">
            <a href="/contact">
              <div>Click here to learn more about our institutional services</div>
            </a>
          </div>
        </div>
        <div className='boxes'>
          <div className='box'>
            <div>
              <div className='title'>Independent Educational Consultants</div>
              <ul>
                <li>Specialized offerings for members of HECA & IECA</li>
                <li>Complimentary practice exams and score report consultations</li>
                <li>Complimentary trial tutoring session for new students</li>
              </ul>
            </div>
            <div className="educators-logos">
              <div className='logo'>
                <img src={heca} />
              </div>
              <div className='logo'>
                <img src={ieca} />
              </div>
            </div>
          </div>

          <div className='box'>
            <div>
              <div className='title'>School Counselors</div>
              <ul>
                <li>Specialized offerings for members of NACAC and its subsidiaries</li>
                <li>Parent presentations and informational resources</li>
                <li>Small Group Tutoring and Bootcamps</li>
              </ul>
            </div>
            <div className="educators-logos">
              <div className='logo'>
                <img src={nacac} />
              </div>
              <div className='logo'>
                <img src={tabs} />
              </div>
              <div className='logo'>
                <img src={cis} />
              </div>
            </div>
          </div>

          <div className='box'>
            <div>
              <div className='title'>School Admin and Parent Teacher Orgs</div>
              <ul>
                <li>Specialized offerings for members of NASSP, PTA, PTO and their subsidiaries</li>
                <li>Larger scale district-wide practice exam and score tracking available</li>
              </ul>
            </div>
            <div className="educators-logos">
              <div className='logo'>
                <img src={nassp} />
              </div>
              <div className='logo'>
                <img src={pto} />
              </div>
              <div className='logo'>
                <img src={pta} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Educators;
