import React from "react";

import "./IconSection.scss";

const IconSection = ({ icon, title, descriptionOne, descriptionTwo }) => {
  return (
    <>
      <div className="icon-section-desktop">
        <div className="icon-icon">
          <img src={icon} alt={title} />
        </div>
        <div className="icon-text">
          <div className="icon-title">{title}</div>
          <div className="icon-description-one">{descriptionOne}</div>
          <div className="icon-description-two">{descriptionTwo}</div>
        </div>
      </div>

      <div className="icon-section-mobile">
        <div className="icon-icon">
          <img src={icon} alt={title} />
        </div>
        <div className="icon-title">{title}</div>
        <div className="icon-text">
          <div className="icon-description-one">{descriptionOne}</div>
          <div className="icon-description-two">{descriptionTwo}</div>
        </div>
      </div>
    </>
  );
};

export default IconSection;
