import React from "react";
import "./PostActions.scss";

const PostActions = ({ post, showFull, admin }) => {
  const confirmFirst = (e) => {
    const confirmation = window.confirm(
      `${admin.username}, are you sure you want to delete this blog post?`
    );
    if (confirmation) {
      return;
    } else {
      e.preventDefault();
    }
  };
  return (
    <div className="post-actions">
      {admin ? (
        <div className="flex">
          <a href={`/posts/${post.id}/edit#post-${post.id}`}>
            <div className="action edit">EDIT POST</div>
          </a>
          <a href={`/posts/${post.id}/delete`} onClick={(e) => confirmFirst(e)}>
            <div className="action">DELETE POST</div>
          </a>
          {showFull && (
            <a href={`/posts#post-${post.id}`}>
              <div className="action">BACK TO LIST</div>
            </a>
          )}
        </div>
      ) : showFull ? (
        <a href={`/posts#post-${post.id}`}>
          <div className="action">BACK TO LIST</div>
        </a>
      ) : (
        <a href={`/posts/${post.id}#post-${post.id}`}>
          <div className="action">READ MORE</div>
        </a>
      )}
    </div>
  );
};

export default PostActions;
