import React, { useEffect, useRef } from 'react';
import SubscriptionForm from '../subscription_form/SubscriptionForm';
import close from '../../../../assets/images/news/close_icon.svg';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import './NewsletterPopup.scss';

const NewsletterPopup = ({ closeNewsletter }) => {
  let overlay = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      overlay.current.classList.remove('close');
    }, 5000);
  });

  const url = `https://ratheg.us20.list-manage.com/subscribe/post?u=8de36277c201171778fd9b2bf&id=a8c24bbe91`;

  return (
    <div
      className="newsletter-overlay close"
      id="newsletter-overlay"
      ref={overlay}
    >
      <div className="newsletter-title">Newsletter</div>
      <div className="newsletter-text">
        Subscribe to Rath Tutoring's newsletter for the latest updates in
        tutoring, test prep, admissions data, and trends in higher education:
      </div>

      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <SubscriptionForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            newsLetterSubscribed={closeNewsletter}
          />
        )}
      />
      <img
        src={close}
        className="close-newsletter"
        onClick={() => closeNewsletter()}
      />
    </div>
  );
};

export default NewsletterPopup;
